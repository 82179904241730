import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, LinearGradient, Stop, Rect, Svg, Defs, Text } from '@react-pdf/renderer';
import { QrCode } from 'javascript-qrcode';
import LogoSmall from "./Logos/LogoSmall";
import LogoQR from "./Logos/LogoQR";


export default function QRCode({ slug, name = 'qr-code' }) {
  const [qr, setQr] = useState();

  useEffect(() => {
    const matrix = new QrCode(`https://i.loadoutapp.com/${slug}`, ['H']);
    setQr(matrix.getData())
  }, [slug]);

  const width = 200;

  const factor = useMemo(() => {
    if (!qr) return;
    return width / qr.length;
  }, [qr, width]);

  return (
    <View style={styles.containerWrapper} wrap={false}>
      <View style={styles.container}>
        <Text style={{ marginBottom: '10px' }}>
          {name}
        </Text>

        <View style={styles.containerOuter}>

          <LogoSmall style={{ position: 'absolute', top: '50%', left: '50%', width: '50px', transform: 'translate(-17.5%, -17.5%)', height: 'auto' }} />
          
          <Svg style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: -1 }} viewBox="0 0 20 20">
            <Defs>
              <LinearGradient id="lineargradient" x1="0" x2="1" y1="0" y2="0">
                <Stop stopColor="#586aa3" />
                <Stop offset="50%" stopColor="#bfd87c" />
                <Stop offset="100%" stopColor="#f7f087" />
              </LinearGradient>
            </Defs>
            <Rect x="0" y="0" rx="10%" ry="10%" width="100%" height="100%" fill="url('#lineargradient')" />
          </Svg>

          <View style={{ ...styles.containerInner, zIndex: -1 }}>
            <View
              style={{
                backgroundColor: '#FFF',
                width: '100%',
                height: '100%',
                borderRadius: '9%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {qr && <Svg width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
                {qr.map((row, y) =>
                  row.map((cell, x) => (
                    <Rect
                      key={x}
                      x={x * factor}
                      y={y * factor}
                      width={1 * factor}
                      height={1 * factor}
                      fill={cell ? '#000' : '#FFF'}
                      style={{ zIndex: -1 }}
                    />
                  ))
                )}
              </Svg>}
            </View>
          </View>

        </View>

        <LogoQR />

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: '40px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderWidth: '1px',
    borderColor: '#000',
    borderStyle: 'solid',
    padding: '20px',
  },
  containerOuter: {
    position: 'relative',
    padding: '10px',
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    width: '240px',
    height: '240px',
    marginBottom: '15px'
  },
  containerInner: {
    position: 'absolute',
    left: 4,
    right: 4,
    top: 4,
    bottom: 4,
    zIndex: -1
  }
});