import { useQuery } from "react-query";
import {
  ref,
  query,
  orderByChild,
  equalTo,
  startAt,
  get,
  endAt
} from "firebase/database";
import { createQuery, db } from "../util/db";

// Subscribe to pack data
export function usePackById(id) {
  return useQuery(
    ["pack", { id }],
    createQuery(() => ref(db, "packs/"+id)),
    { enabled: !!id }
  );
}

//Subscribe to all packs by owner
export function usePacksByUserId(userId) {
  console.log("usePacksByUserId: " + userId)

  return useQuery(
    ["packs", { userId }],
    createQuery(() =>
      query(query(ref(db, 'packs'), orderByChild(`usr/${userId}`)), startAt(0), endAt(5))
    ),
    { enabled: !!userId }
  );
}

  // Subscribe to all packs by tour
  export function usePacksByTourId(tourId) {
    return useQuery(
      ["packsByTour", { tourId }],
      async () => {
        if (!tourId) return [];
  
        // Step 1: Fetch the pack UIDs from the tour reference
        const tourPacksRef = ref(db, `tours/${tourId}/pck`);
        const snapshot = await get(tourPacksRef);
  
        if (!snapshot.exists()) return [];
  
        const packUIDs = Object.keys(snapshot.val()); // Extract pack UIDs from the keys
  
        // Step 2: Fetch pack details for each UID
        const packResults = await Promise.allSettled(
          packUIDs.map(async (packUID) => {
            const packRef = ref(db, `packs/${packUID}`);
            const packSnapshot = await get(packRef);
            if (packSnapshot.exists()) {
              return { id: packUID, ...packSnapshot.val() };
            }
            throw new Error(`Pack with UID ${packUID} not found`);
          })
        );
  
        // Step 3: Filter out rejected results
        const packs = packResults
          .filter((result) => result.status === "fulfilled")
          .map((result) => result.value);
  
        return packs;
      },
      { enabled: !!tourId } // Query is enabled only if tourId exists
    );
  } 


  // export function usePacksByTourId(tourId) {
  //   console.log("usePacksByTourId: " + tourId)
  //   return useQuery(
  //     ["packs", { tourId }],
  //     createQuery(() =>
  //       query(query(ref(db, 'packs'), orderByChild(`det/tUID`)), equalTo(tourId))
  //     ),
  //     { enabled: !!tourId }
  //   );
  // }