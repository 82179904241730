import { child, get, ref, set } from "firebase/database";
import { db, formatSingleDoc, updateRecord } from "../util/db";

export async function getUser(uid) {
  return await get(ref(db, "/users/"+uid)).then(formatSingleDoc);
}

export async function createUser(uid, data) {
  return await set(ref(db, "/users/"+uid), data);
}

export async function updateUser(uid, data) {
  return await updateRecord(`users/${uid}`, data);
}

export async function updateUserTour(uid, tuid, value) {
  return await set(child(ref(db, `users/${uid}`), `tor/${tuid}`), value);
}

export async function updateUserPack(uid, tuid, puid, value) {  
  console.log("UPDATE PACK")
  return await set(child(child(ref(db, `users/${uid}`),`pck/${tuid}`),`/${puid}`), value);
}

export async function updateUserItems(uid, iuid) {
  return await set(child(ref(db, `users/${uid}`), `itm/${iuid}`), 0);
}

export async function createAdminUser(uid) {
  return set(child(ref(db, `data/admin`), `/${uid}`), 0);
}