import { ref, remove, getDatabase, update, serverTimestamp, push, set, child } from "firebase/database";
import { db } from "../util/db";

export function createItem(data) {
  return push(ref(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export async function updateItem(id, data) {
  await set(child(ref(db, `items/${id}`), 'det'), data.det);
  await set(child(ref(db, `items/${id}`), 'pck'), data.pck);
  await set(child(ref(db, `items/${id}`), 'usr'), data.usr);
}

export function deleteItem(itemId, currentUserID) {
  const db = getDatabase();

  const updates = {
    [`items/${itemId}`]: null,
    [`users/${currentUserID}/itm/${itemId}`]: null,
  };

  return update(ref(db), updates)
    .then(() => console.log(`Item ${itemId} deleted successfully.`))
    .catch((error) => console.error("Error deleting item:", error));
}