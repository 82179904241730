import { useQuery } from "react-query";
import {
  ref,
  query,
  get,
  child,
  orderByChild,
  startAt,
  endAt,
  equalTo
} from "firebase/database";
import { createQuery, db } from "../util/db";

// Subscribe to item data 
export function useItemById(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => ref(db, "items/"+id)),
    { enabled: !!id }
  );
}

export function useItemsByUserId(userId) {

  console.log("useItems: " + userId)

  return useQuery(
    ["userItems", userId],
    async () => {
      if (!userId) return [];

      // Fetch user items
      const userItemsRef = ref(db, `users/${userId}/itm`);
      const snapshot = await get(userItemsRef);

      if (!snapshot.exists()) return [];

      const itemUIDs = Object.entries(snapshot.val())
        .filter(([_, value]) => value === 0) // Filter based on your logic
        .map(([key]) => key);

      // Fetch item details with Promise.allSettled
      const itemResults = await Promise.allSettled(
        itemUIDs.map(async (itemUID) => {
          const itemRef = ref(db, `items/${itemUID}`);
          const itemSnapshot = await get(itemRef);
          if (itemSnapshot.exists()) {
            return { id: itemUID, ...itemSnapshot.val() };
          }
          throw new Error(`Item with UID ${itemUID} not found`);
        })
      );

      // Filter out rejected promises
      const items = itemResults
        .filter((result) => result.status === "fulfilled")
        .map((result) => result.value);

      return items;
    },
    { enabled: !!userId } // Ensure the query runs only when a userId exists
  );
}
//   return useQuery(
//     ["userItems", userId],
//     async () => {
//       if (!userId) return [];

//       // Step 1: Fetch the user's itemUIDs
//       const userItemsRef = ref(db, `users/${userId}/itm`);
//       const snapshot = await get(userItemsRef);

//       if (!snapshot.exists()) return [];

//       const itemUIDs = Object.entries(snapshot.val())
//         .filter(([_, value]) => value === 0) // Filter UIDs where value === 0
//         .map(([key]) => key); // Extract the itemUIDs

//       // Step 2: Fetch details for each itemUID and include the UID in the details
//       const itemPromises = itemUIDs.map(async (itemUID) => {
//         const itemRef = ref(db, `items/${itemUID}`);
//         const itemSnapshot = await get(itemRef);
//         if (itemSnapshot.exists()) {
//           return { id: itemUID, ...itemSnapshot.val() }; // Include `itemUID` as `id`
//         }
//         return null;
//       });

//       // Wait for all item fetches to complete
//       const items = await Promise.all(itemPromises);

//       // Filter out any null results (in case an item no longer exists)
//       return items.filter((item) => item !== null);
//     },
//     {
//       enabled: !!userId, // Only fetch if userId is provided
//     }
//   );
// }

// Subscribe to all items by pack

export function useItemsByPackId(packId, userId) {
  return useQuery(
    ["packItems", { packId, userId }],
    async () => {
      if (!userId || !packId) return [];

      const packItemRef = ref(db, `packs/${packId}/itm`);
      const snapshot = await get(packItemRef);

      if (!snapshot.exists()) {
        console.log(`No items found for pack ${packId}`);
        return [];
      }

      const itemUIDs = Object.keys(snapshot.val());
      console.log(`Found ${itemUIDs.length} items for pack ${packId}`);

      const itemResults = await Promise.allSettled(
        itemUIDs.map(async (itemUID) => {
          const itemRef = ref(db, `items/${itemUID}`);
          const itemSnapshot = await get(itemRef);

          if (itemSnapshot.exists()) {
            return { id: itemUID, ...itemSnapshot.val() };
          }
          throw new Error(`Item with UID ${itemUID} not found`);
        })
      );

      const items = itemResults
        .filter((result) => result.status === "fulfilled")
        .map((result) => result.value);

      if (items.length === 0) {
        console.log(`No details found for items in pack ${packId}`);
      }

      return items;
    },
    { enabled: !!userId && !!packId }
  );
}